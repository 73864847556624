// import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// function Dashboard() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       navigate('/login'); // Перенаправление, если токен отсутствует
//     }
//   }, [navigate]);

//   const handleLogout = () => {
//     localStorage.removeItem('token');
//     navigate('/login'); // Перенаправление после разлогина
//   };

//   return (
//     <div style={{ padding: '20px' }}>
//       <h1>Welcome to the Winch Control System</h1>
//       <p>This is a cloud service for cable tensioning management.</p>
//       <p>The winch control panel will be added soon.</p>

//       <button
//         onClick={handleLogout}
//         style={{
//           marginTop: '20px',
//           padding: '10px 20px',
//           backgroundColor: 'red',
//           color: 'white',
//           border: 'none',
//           borderRadius: '4px',
//           cursor: 'pointer',
//         }}
//       >
//         Logout
//       </button>
//     </div>
//   );
// }

// export default Dashboard;


// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import MqttControl from "./MqttControl";

// function Dashboard() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     if (!token) {
//       navigate("/login"); // Перенаправление, если токен отсутствует
//     }
//   }, [navigate]);

//   const handleLogout = () => {
//     localStorage.removeItem("token");
//     navigate("/login"); // Перенаправление после разлогина
//   };

//   // Добавьте ваши deviceId для лебёдок
//   const devices = ["winch-1", "winch-2", "winch-3"]; // Уникальные идентификаторы

//   return (
//     <div style={{ padding: "20px", fontFamily: "Arial, sans-serif", maxWidth: "800px", margin: "0 auto" }}>
//       <header style={{ marginBottom: "20px" }}>
//         <h1>Dashboard: Winch Control System</h1>
//         <p>Manage your winches with real-time control using MQTT.</p>
//       </header>

//       <section>
//         {devices.map((deviceId) => (
//           <MqttControl key={deviceId} deviceId={deviceId} />
//         ))}
//       </section>

//       <footer style={{ marginTop: "20px", textAlign: "center" }}>
//         <button
//           onClick={handleLogout}
//           style={{
//             padding: "10px 20px",
//             backgroundColor: "gray",
//             color: "white",
//             border: "none",
//             borderRadius: "4px",
//             cursor: "pointer",
//           }}
//         >
//           Logout
//         </button>
//       </footer>
//     </div>
//   );
// }

// export default Dashboard;


import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MqttControl from './MqttControl';

function Dashboard() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login'); // Перенаправление, если токен отсутствует
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login'); // Перенаправление после разлогина
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>Dashboard: Winch Control System</h1>
      <p>Manage your winch with real-time control using MQTT.</p>
      
      {/* Встраивание MqttControl */}
      <MqttControl />

      {/* Кнопка Logout */}
      <button
        onClick={handleLogout}
        style={{
          marginTop: '20px',
          padding: '10px 20px',
          backgroundColor: 'gray',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        Logout
      </button>
    </div>
  );
}

export default Dashboard;
