import React, { useEffect, useState } from "react";
import Paho from "paho-mqtt";

const MqttControl = () => {
  const [connectionStatus, setConnectionStatus] = useState(false); // Статус подключения
  const [statusMessage, setStatusMessage] = useState(""); // Статус сообщений от брокера
  const [client, setClient] = useState(null); // Сохраняем MQTT клиент

  useEffect(() => {
    // Создаём клиента MQTT один раз
    // const mqttClient = new Paho.Client("31.128.45.211", 9001, "react-client");
    const mqttClient = new Paho.Client("wss://cableforce-dashboard.pro/mqtt", "react-client");
    // const mqttClient = new Paho.Client("wss://31.128.45.211/ws", "react-client");


    // Устанавливаем обработчики событий
    mqttClient.onConnectionLost = (responseObject) => {
      console.log("Connection lost:", responseObject.errorMessage);
      setConnectionStatus(false);
    };

    mqttClient.onMessageArrived = (message) => {
      console.log("Message received:", message.payloadString);
      setStatusMessage(`Message on ${message.destinationName}: ${message.payloadString}`);
    };

    // Подключаемся к брокеру
    mqttClient.connect({
      onSuccess: () => {
        console.log("Connected to MQTT broker");
        setConnectionStatus(true);
        mqttClient.subscribe("motor/control");
        setClient(mqttClient); // Сохраняем клиент в состоянии
      },
      onFailure: (error) => {
        console.error("Connection failed:", error);
      },
    });

    // Очистка при размонтировании компонента
    return () => {
      if (mqttClient.isConnected()) {
        mqttClient.disconnect();
        console.log("Disconnected from MQTT broker");
      }
    };
  }, []);

  // Отправка сообщения
  const sendMessage = (command) => {
    if (client && client.isConnected()) {
      const message = new Paho.Message(command);
      message.destinationName = "motor/control";
      client.send(message); // Используем уже подключенный клиент
      console.log(`Sent command: ${command}`);
    } else {
      console.error("Cannot send message: MQTT client is not connected");
    }
  };

  return (
    <div>
      <h1>MQTT Winch Control</h1>
      <div>
        <span
          style={{
            display: "inline-block",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: connectionStatus ? "green" : "red",
            marginRight: "10px",
          }}
        ></span>
        {connectionStatus ? "Connected" : "Disconnected"}
      </div>
      <p>Status: {statusMessage}</p>
      <button onClick={() => sendMessage("on")} className="btn btn-success">
        Start
      </button>
      <button onClick={() => sendMessage("off")} className="btn btn-danger">
        Stop
      </button>
      <button onClick={() => sendMessage("reverse_on")} className="btn btn-warning">
        Reverse On
      </button>
      <button onClick={() => sendMessage("reverse_off")} className="btn btn-warning">
        Reverse Off
      </button>
    </div>
  );
};

export default MqttControl;


// import React, { useEffect, useState } from "react";
// import Paho from "paho-mqtt";

// const MqttControl = ({ deviceId }) => {
//   const [connectionStatus, setConnectionStatus] = useState(false); // Статус подключения к брокеру
//   const [deviceStatus, setDeviceStatus] = useState(false); // Статус подключения лебёдки
//   const [statusMessage, setStatusMessage] = useState(""); // Последнее сообщение от лебёдки
//   const [client, setClient] = useState(null); // MQTT клиент

//   useEffect(() => {
//     // Создаём клиента MQTT
//     const mqttClient = new Paho.Client("31.128.45.211", 9001, `react-client-${deviceId}`);

//     // Обработчики событий MQTT
//     mqttClient.onConnectionLost = (responseObject) => {
//       console.log("Connection lost:", responseObject.errorMessage);
//       setConnectionStatus(false);
//     };

//     mqttClient.onMessageArrived = (message) => {
//       console.log("Message received:", message.payloadString);

//       const parsedMessage = JSON.parse(message.payloadString);
//       if (parsedMessage.deviceId === deviceId) {
//         // Обновляем статус подключения лебёдки
//         setDeviceStatus(parsedMessage.status === "online");
//         setStatusMessage(`Device ${deviceId}: ${parsedMessage.status}`);
//       }
//     };

//     // Подключаемся к брокеру
//     mqttClient.connect({
//       onSuccess: () => {
//         console.log(`Connected to MQTT broker for device ${deviceId}`);
//         setConnectionStatus(true);
//         mqttClient.subscribe("motor/status"); // Подписываемся на топик статусов
//         setClient(mqttClient);
//       },
//       onFailure: (error) => {
//         console.error("Connection failed:", error);
//       },
//     });

//     // Очистка при размонтировании компонента
//     return () => {
//       if (mqttClient.isConnected()) {
//         mqttClient.disconnect();
//         console.log("Disconnected from MQTT broker");
//       }
//     };
//   }, [deviceId]);

//   // Отправка команды устройству
//   const sendMessage = (command) => {
//     if (client && client.isConnected()) {
//       const message = new Paho.Message(JSON.stringify({ deviceId, command }));
//       message.destinationName = "motor/control";
//       client.send(message);
//       console.log(`Sent command to ${deviceId}: ${command}`);
//     } else {
//       console.error("Cannot send message: MQTT client is not connected");
//     }
//   };

//   return (
//     <div style={{ marginBottom: "20px" }}>
//       <h3>Winch Control: {deviceId}</h3>
//       <div>
//         <span
//           style={{
//             display: "inline-block",
//             width: "20px",
//             height: "20px",
//             borderRadius: "50%",
//             backgroundColor: connectionStatus ? "green" : "red",
//             marginRight: "10px",
//           }}
//         ></span>
//         {connectionStatus ? "Connected to broker" : "Disconnected from broker"}
//       </div>
//       <div>
//         <span
//           style={{
//             display: "inline-block",
//             width: "20px",
//             height: "20px",
//             borderRadius: "50%",
//             backgroundColor: deviceStatus ? "green" : "red",
//             marginRight: "10px",
//           }}
//         ></span>
//         {deviceStatus ? `Device ${deviceId} Online` : `Device ${deviceId} Offline`}
//       </div>
//       <p>Status: {statusMessage}</p>
//       <button onClick={() => sendMessage("on")} className="btn btn-success">
//         Start
//       </button>
//       <button onClick={() => sendMessage("off")} className="btn btn-danger">
//         Stop
//       </button>
//       <button onClick={() => sendMessage("reverse_on")} className="btn btn-warning">
//         Reverse On
//       </button>
//       <button onClick={() => sendMessage("reverse_off")} className="btn btn-warning">
//         Reverse Off
//       </button>
//     </div>
//   );
// };

// export default MqttControl;
